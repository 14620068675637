<template>
  <b-card class="blog-edit-wrapper">
    <!-- form -->
    <div class="text-center">
      <h4 class="mt-5">
        Update event
        <feather-icon icon="Edit2Icon" />
      </h4>
    </div>
      <b-modal id="image-properties-modal" title="Image Properties">
      <b-form>
        <b-form-group label="Alt Text">
          <b-form-input v-model="imageProperties.alt"></b-form-input>
        </b-form-group>
        <b-form-group label="Width">
          <b-form-input v-model="imageProperties.width" placeholder="e.g., 100px or 50%"></b-form-input>
        </b-form-group>
        <b-form-group label="Height">
          <b-form-input v-model="imageProperties.height" placeholder="e.g., 100px or auto"></b-form-input>
        </b-form-group>
        <b-form-group label="Alignment">
          <b-form-select v-model="imageProperties.alignment">
            <option value="">None</option>
            <option value="ql-align-left">Left</option>
            <option value="ql-align-center">Center</option>
            <option value="ql-align-right">Right</option>
          </b-form-select>
        </b-form-group>
        <b-button variant="primary" @click="insertImage">Insert</b-button>
      </b-form>
    </b-modal>
    <validation-observer ref="addeventFormalidate">
      <b-form
        ref="form"
        class="mt-2"
        style="width: 100%"
        @submit.prevent="save"
      >
        <b-row>
          <b-col md="4">
            <b-form-group
              label="event Name"
              label-for="blog-edit-title"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="event Name"
                rules="required"
              >
                <b-form-input
                  id="blog-edit-title"
                  v-model="addeventForm.name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
              label="In-Link Name"
              label-for="blog-edit-slug"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="In-Link Name"
                rules="required"
              >
                <b-form-input
                  id="blog-edit-slug"
                  v-model="addeventForm.link_id"
                  @input="removeSpace($event)"
                  @change="checkLink"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small
                  v-if="!validLink"
                  class="text-danger"
                >Not Valid</small>
                event link after creation:{{
                  `https://lpcentre.com/events/${addeventForm.link_id}`
                }}
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Duration "
              label-for="blog-edit-event"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="duration Link"
              >
                <b-form-input
                  id="video_link"
                  v-model="addeventForm.duration"
                  type="number"
                  @blur="getDurationList"
                />

              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="date"
              label-for="date"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="date"
                rules="required"
              >
              
                <flat-pickr
                  v-model="addeventForm.date"
                  class="form-control"
                  :config="{ enableTime: true, dateFormat: 'Y-m-d H:i'}"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="2">
            <label>Save Event As</label>

            <b-form-radio-group
              v-model="addeventForm.online"
              label="saveas"
              class="custom-control-warning mb-2"
            >
              <b-form-radio value="1">
                Online
              </b-form-radio>
              <b-form-radio value="0">
                Classical
              </b-form-radio>
              <!-- <b-form-radio value="Both">
                Both
              </b-form-radio> -->
            </b-form-radio-group>
          </b-col>
          <b-col md="2">
            <label>Is Free</label>

            <b-form-radio-group
              v-model="addeventForm.is_free"
              label="is_free"
              class="custom-control-warning mb-2"
            >
              <b-form-radio value="1">
                yes
              </b-form-radio>
              <b-form-radio value="0">
                no
              </b-form-radio>
              <!-- <b-form-radio value="Both">
                Both
              </b-form-radio> -->
            </b-form-radio-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              label="Venue"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="venue"
                rules="required"
              >
                <b-form-input
                  id="video_link"
                  v-model="addeventForm.venue"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
              label="Related Events"
              label-for="blog-edit-category"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Show In"
                rules=""
              >
                <v-select
                  id="blog-edit-events"
                  v-model="addeventForm.related_events"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="eventsList"
                  multiple
                  :reduce="(val) => val.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Related News"
              label-for="blog-edit-news"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Show In"
                rules=""
              >
                <v-select
                  id="blog-edit-events"
                  v-model="addeventForm.related_news"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="newsList"
                  multiple
                  :reduce="(val) => val.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Related Speakers"
              label-for="blog-edit-category"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Show In"
                rules=""
              >
                <v-select
                  id="blog-edit-related_speakers"
                  v-model="addeventForm.speakers_array"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="full_name"
                  :options="speakersList"
                  multiple
                  :reduce="(val) => val.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Related Organisers"
              label-for="blog-edit-category"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Show In"
                rules=""
              >
                <v-select
                  id="blog-edit-organisers"
                  v-model="addeventForm.orgnisers_array"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="organisersList"
                  multiple
                  :reduce="(val) => val.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <label> Card Gradiant Color</label>
            <!-- <Vue2ColorPicker v-model="addeventForm.card_gradiant_color"></Vue2ColorPicker> -->
            <vue-color-picker
              v-model="addeventForm.card_gradiant_color"
              class="form-control"
            />
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Video Link"
              label-for="blog-edit-category"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="YouTube Link"
              >
                <b-form-input
                  id="video_link"
                  v-model="addeventForm.video_link"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="brochure "
              label-for="blog-edit-category"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="brochure Link"
              >

                <b-form-file
                  ref="refInputE2"
                  v-model="addeventForm.brochure"

                  placeholder="Choose file"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <a
                  target="_blank"
                  :href="`https://lpcentre.com/${addeventForm.brochure}`"
                >
                  <feather-icon icon="LinkIcon" />
                </a>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="mb-2"
          >
            <div class="border rounded p-2">
              <h4 class="mb-1">
                Tab image
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <div class="d-inline-block">
                  <b-media-aside>
                    <b-img
                      ref="refPreviewE2"

                      :src="addeventForm.tab_image ? `https://lpcentre.com/${addeventForm.tab_image} ` : 'https://www.lpcentre.com/images/no_image.png'"
                      height="110"
                      width="170"
                      class="rounded mr-2 mb-1 mb-md-0"
                    />
                  </b-media-aside>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    class="rounded ml-1 mt-2 text-center"
                    @click="refPreviewE2.src = Tabimage
                            addeventForm.tab_image=''"
                  >
                    Remove Image
                  </b-button>
                </div>
                <b-media-body>
                  <b-card-text class="mt-1" />
                  <div class="d-inline-block mt-1">
                    <b-form-group
                      label="Tab Image "
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Tab Image "
                        rules="required"
                      >
                        <b-form-file
                          ref="refInputE2"
                          v-model="addeventForm.tab_image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRendererTab"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Tab Image Alt Text"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <b-form-input
                        id="alt-image"
                        v-model="addeventForm.alt_tab_image"
                      />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>
            <div class="border rounded p-2">
              <h4 class="mb-1">
                Header image
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <div class="d-inline-block">
                  <b-media-aside>
                    <b-img
                      ref="refPreviewEl"

                      :src="addeventForm.header_image ? `https://lpcentre.com/${addeventForm.header_image} ` : 'https://www.lpcentre.com/images/no_image.png'"
                      height="110"
                      width="170"
                      class="rounded mr-2 mb-1 mb-md-0"
                    />
                  </b-media-aside>

                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    class="rounded ml-1 mt-2 text-center"
                    @click="refPreviewEl.src = Tabimage
                            addeventForm.header_image=''

                    "
                  >
                    Remove Image
                  </b-button>
                </div>
                <b-media-body>
                  <b-card-text class="mt-1" />
                  <div class="d-inline-block mt-1">
                    <b-form-group
                      label="Header Image "
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Icon Image "
                        rules="required"
                      >
                        <b-form-file
                          ref="refInputEl"
                          v-model="addeventForm.header_image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRenderer"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Header image Alt Text"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <b-form-input
                        id=" alt_header_image"
                        v-model="addeventForm.alt_header_image"
                      />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>
            <div class="border rounded p-2">
              <h4 class="mb-1">
                Og image
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <div class="d-inline-block">
                  <b-media-aside>
                    <b-img
                      ref="refPreviewE3"

                      :src="addeventForm.og_image ? `https://lpcentre.com/${addeventForm.og_image} ` : 'https://www.lpcentre.com/images/no_image.png'"
                      height="110"
                      width="170"
                      class="rounded mr-2 mb-1 mb-md-0"
                    />
                  </b-media-aside>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    class="rounded ml-1 mt-2 text-center"
                    @click="refInputE3.src = Tabimage
                            addeventForm.og_image=''"
                  >
                    Remove Image
                  </b-button>
                </div>
                <b-media-body>
                  <b-card-text class="mt-1" />
                  <div class="d-inline-block mt-1">
                    <b-form-group
                      label="Tab Image "
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Tab Image "
                      >
                        <b-form-file
                          ref="refInputE3"
                          v-model="addeventForm.og_image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRendererTab3"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                  </div>
                </b-media-body>
              </b-media>
            </div>
            <div class="border rounded p-2">
              <h4 class="mb-1">
              Mobile Image
              </h4>
              <b-media
                no-body
                vertical-align="center"
                class="flex-column flex-md-row"
              >
                <div class="d-inline-block">
                  <b-media-aside>
                    <b-img
                      ref="refPreviewEM"
                      :src="Tabimage"
                      height="110"
                      width="170"
                      class="rounded mr-2 mb-1 mb-md-0 col-12"
                    />
                  </b-media-aside>

                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-secondary"
                    class="rounded ml-1 mt-2 text-center"
                    @click="
                      refPreviewE3.src = Tabimage;
                      addeventForm.mobile_header_image = '';
                    "
                  >
                    Remove Image
                  </b-button>
                </div>
                <b-media-body>
                  <b-card-text class="mt-1" />
                  <div class="d-inline-block mt-1">
                    <b-form-group
                      label="Mobile Image"
                      label-for="blog-tab-title"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="mobile_header_image"
                      >
                        <b-form-file
                          ref="refInputEM"
                          v-model="addeventForm.mobile_header_image"
                          accept=".jpg, .png, .gif, .jpeg"
                          placeholder="Choose file"
                          @input="inputImageRendererTab7"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Tab Image Alt Text"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <b-form-input
                        id="alt-image"
                        v-model="addeventForm.alt_mobile_header_image"
                      />
                    </b-form-group>
                  </div>
                </b-media-body>
              </b-media>
            </div>
          </b-col>
          <b-col
            md="6"
            xl="6"
            sm="12"
          > <div class="border rounded p-2">
            <h4 class="mb-1">
              Certificate image
            </h4>
            <b-media
              no-body
              vertical-align="center"
              class="flex-column flex-md-row"
            >
              <div class="d-inline-block">
                <b-media-aside>
                  <b-img
                    ref="refPreviewEC"

                    :src="addeventForm.certificate_image ? `https://lpcentre.com/${addeventForm.certificate_image} ` : 'https://www.lpcentre.com/images/no_image.png'"
                    height="110"
                    width="170"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  class="rounded ml-1 mt-2 text-center"
                  @click="refInputEC.src = Tabimage
                          addeventForm.certificate_image=''"
                >
                  Remove Image
                </b-button>
              </div>
              <b-media-body>
                <b-card-text class="mt-1" />
                <div class="d-inline-block mt-1">
                  <b-form-group
                    label="Tab Image "
                    label-for="blog-edit-title"
                    class="mb-2"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Tab Image "
                    >
                      <b-form-file
                        ref="refInputEC"
                        v-model="addeventForm.certificate_image"
                        accept=".jpg, .png, .gif, .jpeg"
                        placeholder="Choose file"
                        @input="inputImageRendererTab4"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                </div>
              </b-media-body>
            </b-media>
          </div>
          </b-col>
          <b-col
            md="6"
            xl="6"
            sm="12"
          >
            <b-form-group
              label="certificate Tilte"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="certificate"
              >
                <b-form-input
                  id="certificate_title"
                  v-model="addeventForm.certificate_title"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              label="Certificate"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="certificate"
              >
                    <quill-editor
                  ref="certificateEditor"
                  v-model="addeventForm.certificate"
                  :options="getEditorOptions('certificateEditor')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="6"
            sm="12"
          > <div class="border rounded p-2">
            <h4 class="mb-1">
              Seamless image
            </h4>
            <b-media
              no-body
              vertical-align="center"
              class="flex-column flex-md-row"
            >
              <div class="d-inline-block">
                <b-media-aside>
                  <b-img
                    ref="refPreviewES"

                    :src="addeventForm.seamless_image ? `https://lpcentre.com/${addeventForm.seamless_image} ` : 'https://www.lpcentre.com/images/no_image.png'"
                    height="110"

                    width="170"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  class="rounded ml-1 mt-2 text-center"
                  @click="refPreviewES.src = Tabimage
                          addeventForm.seamless_image=''"
                >
                  Remove Image
                </b-button>
              </div>
              <b-media-body>
                <b-card-text class="mt-1" />
                <div class="d-inline-block mt-1">
                  <b-form-group
                    label="Tab Image "
                    label-for="blog-edit-title"
                    class="mb-2"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Tab Image "
                    >
                      <b-form-file
                        ref="refInputES"
                        v-model="addeventForm.seamless_image"
                        accept=".jpg, .png, .gif, .jpeg"
                        placeholder="Choose file"
                        @input="inputImageRendererTab5"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                </div>
              </b-media-body>
            </b-media>
          </div>
          </b-col>
          <b-col
            md="6"
            xl="6"
            sm="12"
          >
            <b-form-group
              label="Seamless Title"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="seamless"
              >
                <b-form-input
                  id="seamless_title"
                  v-model="addeventForm.seamless_title"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Seamless"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="seamless"
              >
                 <quill-editor
                  ref="seamlessEditor"
                  v-model="addeventForm.seamless"
                  :options="getEditorOptions('seamlessEditor')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="6"
            sm="12"
          > <div class="border rounded p-2">
            <h4 class="mb-1">
              Networking image
            </h4>
            <b-media
              no-body
              vertical-align="center"
              class="flex-column flex-md-row"
            >
              <div class="d-inline-block">
                <b-media-aside>
                  <b-img
                    ref="refPreviewEN"
                    :src="addeventForm.networking_image ? `https://lpcentre.com/${addeventForm.networking_image} ` : 'https://www.lpcentre.com/images/no_image.png'"
                    height="110"
                    width="170"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  class="rounded ml-1 mt-2 text-center"
                  @click="refPreviewEN.src = Tabimage
                          addeventForm.networking_image=''"
                >
                  Remove Image
                </b-button>
              </div>
              <b-media-body>
                <b-card-text class="mt-1" />
                <div class="d-inline-block mt-1">
                  <b-form-group
                    label="Tab Image "
                    label-for="blog-edit-title"
                    class="mb-2"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Tab Image "
                    >
                      <b-form-file
                        ref="refInputEN"
                        v-model="addeventForm.networking_image"
                        accept=".jpg, .png, .gif, .jpeg"
                        placeholder="Choose file"
                        @input="inputImageRendererTab6"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                </div>
              </b-media-body>
            </b-media>
          </div>

          </b-col>
          <b-col
            md="6"
            xl="6"
            sm="12"
          >
            <b-form-group
              label="Networking Title"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="networking"
              >
                <b-form-input
                  id="video_link"
                  v-model="addeventForm.networking_title"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Networking"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="networking"
              >
               <quill-editor
                  ref="networkingEditor"
                  v-model="addeventForm.networking"
                  :options="getEditorOptions('networkingEditor')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="6"
            sm="12"
          > <div class="border rounded p-2">
            <h4 class="mb-1">
              Participate image
            </h4>
            <b-media
              no-body
              vertical-align="center"
              class="flex-column flex-md-row"
            >
              <div class="d-inline-block">
                <b-media-aside>
                  <b-img
                    ref="refPreviewEP"

                    :src="addeventForm.participate_image ? `https://lpcentre.com/${addeventForm.participate_image} ` : 'https://www.lpcentre.com/images/no_image.png'"

                    height="110"
                    width="170"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  class="rounded ml-1 mt-2 text-center"
                  @click="refPreviewEP.src = Tabimage
                          addeventForm.participate_image=''"
                >
                  Remove Image
                </b-button>
              </div>
              <b-media-body>
                <b-card-text class="mt-1" />
                <div class="d-inline-block mt-1">
                  <b-form-group
                    label="Tab Image "
                    label-for="blog-edit-title"
                    class="mb-2"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="participate_image"
                    >
                      <b-form-file
                        ref="refInputEP"
                        v-model="addeventForm.participate_image"
                        accept=".jpg, .png, .gif, .jpeg"
                        placeholder="Choose file"
                        @input="inputImageRendererTab2"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                </div>
              </b-media-body>
            </b-media>
          </div>

          </b-col>
          <b-col
            md="6"
            xl="6"
            sm="12"
          >
            <b-form-group
              label="Participate Title"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="participate_title"
              >
                <b-form-input
                  id="video_link"
                  v-model="addeventForm.participate_title"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Participate"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="participate"
              >
                <quill-editor
                  ref="participateEditor"
            v-model="addeventForm.participate"
                  :options="getEditorOptions('participateEditor')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="About event"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="about"
              >
              <quill-editor
                  ref="aboutEditor"
                  v-model="addeventForm.about"
                  :options="getEditorOptions('aboutEditor')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Attenders  "
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="attenders"
              >
                  <quill-editor
                  ref="attendersEditor"
                  v-model="addeventForm.attenders"
                  :options="getEditorOptions('attendersEditor')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Speakers"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="speakers"
              >
                <quill-editor
                  ref="speakersEditor"
                  v-model="addeventForm.speakers"
                  :options="getEditorOptions('speakersEditor')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <h3> Event agenda</h3>
            <b-form-group
              label="Select Day"
              label-for="blog-edit-course"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="day"
                rules=""
              >
                <v-select
                  id="blog-s-category"
                  v-model="addeventForm.day"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="durationList"
                  @input="getDayContent(addeventForm.day)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Agenda"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="agenda"
              >
                <quill-editor
                  ref="dayContentFormE"
                  v-model="dayContentForm"
                  :options="getEditorOptions('dayContentFormE')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="Organisers"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="organisers"
              >
               <quill-editor
                  ref="organisersEditor"
                  v-model="addeventForm.organisers"
                  :options="getEditorOptions('organisersEditor')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <div class="border rounded p-3">
              <b-row v-if="addeventForm.is_free=='1'">
                <b-col cols="4">
                  <b-form-group
                    label="Early bird price"
                    label-for="blog-edit-title"
                    class="mb-2"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Early bird price "
                    >
                      <b-form-input
                        id="blog-edit-title"
                        v-model="addeventForm.early_bird_price"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    label="Regular Price "
                    label-for="blog-edit-title"
                    class="mb-2"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Event regular price"
                    >
                      <b-form-input
                        id="blog-edit-title"
                        v-model="addeventForm.regular_price"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    label="End Early bird date"
                    label-for="date"
                    class="mb-2"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="End early bird date"
                    >
                      <flat-pickr
                        v-model="addeventForm.end_early_bird_date"
                        class="form-control"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row v-else>
                <b-col cols="4">
                  <b-form-group
                    label="Early bird price"
                    label-for="blog-edit-title"
                    class="mb-2"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="early_bird_price "
                      rules="required"
                    >
                      <b-form-input
                        id="blog-edit-title"
                        v-model="addeventForm.early_bird_price"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    label="Regular Price "
                    label-for="blog-edit-title"
                    class="mb-2"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="event regular_price"
                      rules="required"
                    >
                      <b-form-input
                        id="blog-edit-title"
                        v-model="addeventForm.regular_price"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    label="End Early bird date"
                    label-for="date"
                    class="mb-2"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="end_early_bird_date"
                      rules="required"
                    >
                      <flat-pickr
                        v-model="addeventForm.end_early_bird_date"
                        class="form-control"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-col cols="12">
                <b-form-group
                  label="Fees"
                  label-for="blog-content"
                  class="mb-2"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="fees"
                  >
                     <quill-editor
                  ref="feesEditor"
                  v-model="addeventForm.fees"
                  :options="getEditorOptions('feesEditor')"
                />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </div>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Register"
              label-for="blog-content"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="register"
              >
                  <quill-editor
                  ref="registerEditor"
                  v-model="addeventForm.register"
                  :options="getEditorOptions('registerEditor')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="4">
            <b-form-group
              label="Meta Keywords"
              label-for="blog-content"
              class="mb-2"
            >
              <b-form-tags v-model="addeventForm.meta_keywords" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label=" Meta Title"
              label-for="blog-edit-title"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Meta Title"
              >
                <b-form-input
                  id="blog-edit-title"
                  v-model="addeventForm.meta_title"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group
              label="Meta Description"
              label-for="blog-edit-slug"
              class="mb-2"
            >
              <validation-provider
                #default="{ errors }"
                name="Meta Description"
              >
                <b-form-input
                  id="blog-edit-slug"
                  v-model="addeventForm.meta_description"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="mt-50"
          >
            <b-button
            data-testid="event-update-button"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>

          </b-col>
          </b-col></b-col></b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import {
  ref, reactive, computed, watch,getCurrentInstance
} from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import Vue from 'vue'
import store from '@/store'
import Editor from '@tinymce/tinymce-vue'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
// Import Quill and define custom size style
import Quill from 'quill'
const SizeStyle = Quill.import('attributors/style/size')
SizeStyle.whitelist = ['10px', '12px', '14px', '16px', '18px', '20px', '24px', '30px', '32px', '36px', '48px', '60px', '72px', '96px']
Quill.register(SizeStyle, true)

// Create a custom Image format
const BlockEmbed = Quill.import('blots/block/embed')
class ImageFormat extends BlockEmbed {
  static create(value) {
    let node = super.create(value)
    node.setAttribute('src', value.src)
    node.setAttribute('alt', value.alt || '')
    node.setAttribute('width', value.width || '')
    node.setAttribute('height', value.height || '')
    node.setAttribute('class', value.class || '')
    return node
  }

  static value(node) {
    return {
      src: node.getAttribute('src'),
      alt: node.getAttribute('alt'),
      width: node.getAttribute('width'),
      height: node.getAttribute('height'),
      class: node.getAttribute('class'),
    }
  }
}
ImageFormat.blotName = 'image'
ImageFormat.tagName = 'img'
Quill.register(ImageFormat, true)

export default {
  setup(props) {
    const refInputEl = ref(null)
    const refInputE2 = ref(null)
    const refPreviewEl = ref(null)
    const validLink = ref(true)
    const checkLink = link => {
      const formData = new FormData()

      formData.append('slug', link)
      store.dispatch('courses/checkLink', formData)
        .then(response => {
          validLink.value = response.data.valid
        })
    }
    const refPreviewE2 = ref(null)
    const eventForm = computed(() => store.state.events.event)
    const addeventForm = reactive(eventForm)
    const Tabimage = ref('media/svg/files/blank-image.svg')
    const { route } = useRouter()
    const { id } = route.value.params
    //   const productId = productSlug.substring(id.lastIndexOf('-') + 1)

    store.dispatch('events/Getevent', { id }).then(response => {
      store.commit('events/GET_event', response?.data.data)
      // addeventForm.day=1

      for (let i = 1; i <= response?.data.data.duration; i++) {
        durationList.value.push(i)
      }

      dayContent.value = JSON.parse(response?.data.data.agenda)

      daycontentArray.value = dayContent.value
      dayContentForm.value = dayContent.value[0]
      addeventForm.value.day = 1
      addeventForm.value.orgnisers_array = JSON.parse(response?.data.data.orgnisers_array)
      addeventForm.value.speakers_array = JSON.parse(response?.data.data.speakers_array)
      addeventForm.value.related_events = JSON.parse(response?.data.data.related_events)
      addeventForm.value.related_news = JSON.parse(response?.data.data.related_news)
    })

    const refInputEC = ref(null)
    const refPreviewEC = ref(null)

    const refInputES = ref(null)
    const refPreviewES = ref(null)

    const refInputEN = ref(null)
    const refPreviewEN = ref(null)

    const refInputEP = ref(null)
    const refPreviewEP = ref(null)
    const { inputImageRendererTab4 } = useInputImageRenderer(
      refInputEC,
      base64 => {
        refPreviewEC.value.src = base64
      },
    )
    const { inputImageRendererTab5 } = useInputImageRenderer(
      refInputES,
      base64 => {
        refPreviewES.value.src = base64
      },
    )
    const { inputImageRendererTab6 } = useInputImageRenderer(
      refInputEN,
      base64 => {
        refPreviewEN.value.src = base64
      },
    )
    const { inputImageRendererTab2 } = useInputImageRenderer(
      refInputEP,
      base64 => {
        refPreviewEP.value.src = base64
      },
    )
    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      base64 => {
        refPreviewEl.value.src = base64
      },
    )
    const { inputImageRendererTab } = useInputImageRenderer(
      refInputE2,
      base64 => {
        refPreviewE2.value.src = base64
      },
    )
    const dayContent = ref([])
    const daycontentArray = ref([])
    const dayContentForm = ref('')
    watch(dayContentForm, (val, oldVal) => {
      if (!dayContentForm.value) {
        dayContentForm.value = ''
      }

      if (dayContentForm.value !== '') {
        daycontentArray.value[addeventForm.value.day - 1] = dayContentForm.value
      } else if (daycontentArray.value[addeventForm.value.day - 1]) {
        dayContentForm.value = daycontentArray.value[addeventForm.value.day - 1]
        // daycontentArray.value[addeventForm.day-1]= daycontentArray.value[addeventForm.day-1]
      }
    })

    const durationList = ref([])
    const getDurationList = () => {
      durationList.value = []
      console.log(addeventForm.value.duration)

      for (let i = 1; i <= addeventForm.value.duration; i++) {
        durationList.value.push(i)
      }
    }
    const getDayContent = num => {
      console.log(daycontentArray.value)
      console.log(dayContent.value)
      //  if(dayContent.value[num-1]){
      if (dayContent.value) {
        if (dayContent.value[num - 1] !== null) {
          dayContentForm.value = daycontentArray.value[num - 1]
        } else {
          dayContentForm.value = ''
        }
      } else {
        dayContentForm.value = ''
      }

      // CKEDITOR.instances.day_content.setData(dayContentForm.value[parseInt(i)-1]);
    }
    const addeventFormalidate = ref()
    const removeSpace = target => {
      addeventForm.value.link_id = target.toLowerCase() // LowerCase
        .replace(/\s+/g, '-') // space to -
        .replace(/&/g, '') // & to and
        .replace(/--/g, '-')
        .replace(/[.,]/g, '-')
        .replace(/[_]/g, '')
        .replace(/[!''""=]/g, '')
        .replace(/[!?%$^~*#()+]/g, '')
    }
    const save = () => {
      console.log(addeventForm.value)
      if (validLink.value == true) {
        const formData = new FormData()
        formData.append('link_id', addeventForm.value.link_id)

        if (addeventForm.value.fees !== null) {
          formData.append('fees', addeventForm.value.fees)
        }
        formData.append('duration', addeventForm.value.duration)
        // formData.append("link_id", addeventForm.value.link_id);
        formData.append('online', addeventForm.value.online)
        formData.append('tab_image', addeventForm.value.tab_image)
        formData.append('header_image', addeventForm.value.header_image)
        formData.append('alt_tab_image', addeventForm.value.alt_tab_image)
        formData.append('_method', 'put')
        formData.append('alt_header_image', addeventForm.value.alt_header_image)
        if (addeventForm.value.certificate_title !== null) {
          
          formData.append('certificate_title', addeventForm.value.certificate_title)
        }
        if (addeventForm.value.certificate_image !== null) {
          formData.append('certificate_image', addeventForm.value.certificate_image)
        }
        if (addeventForm.value.seamless_title !== null) {
          formData.append('seamless_title', addeventForm.value.seamless_title)
        }
        if (addeventForm.value.participate_title !== null) {
          formData.append('participate_title', addeventForm.value.participate_title)
        }
        if (addeventForm.value.networking_title !== null && addeventForm.value.networking_title != 'null' && addeventForm.value.networking_title != null) {
          formData.append('networking_title', addeventForm.value.networking_title)
        }

        formData.append('seamless_image', addeventForm.value.seamless_image)

        formData.append('networking_image', addeventForm.value.networking_image)

        formData.append('participate_image', addeventForm.value.participate_image)

        formData.append('card_gradiant_color', addeventForm.value.card_gradiant_color)

        formData.append('name', addeventForm.value.name)
        if (addeventForm.value.meta_keywords !== null) {
          formData.append('meta_keywords', addeventForm.value.meta_keywords)
        }
        if (addeventForm.value.meta_description !== null) {
          formData.append('meta_description', addeventForm.value.meta_description)
        }
        if (addeventForm.value.meta_title !== null) {
          formData.append('meta_title', addeventForm.value.meta_title)
        }
        if (addeventForm.value.certificate !== null) {
          formData.append('certificate', addeventForm.value.certificate)
        }
        if (addeventForm.value.register !== null) {
          formData.append('register', addeventForm.value.register)
        }

        if (addeventForm.value.about !== null) {
          formData.append('about', addeventForm.value.about)
        }
        if (addeventForm.value.attenders !== null) {
          formData.append('attenders', addeventForm.value.attenders)
        }
        if (addeventForm.value.speakers !== null) {
          formData.append('speakers', addeventForm.value.speakers)
        }
        if (addeventForm.value.participate !== null) {
          formData.append('participate', addeventForm.value.participate)
        }
        if (addeventForm.value.networking !== null) {
          formData.append('networking', addeventForm.value.networking)
        }
        if (addeventForm.value.seamless !== null) {
          formData.append('seamless', addeventForm.value.seamless)
        }
        if (addeventForm.value.organisers !== null) {
          formData.append('organisers', addeventForm.value.organisers)
        }
        if (addeventForm.value.video_link !== null) {
          formData.append('video_link', addeventForm.value.video_link)
        }
        formData.append('brochure', addeventForm.value.brochure)
        formData.append('is_free', addeventForm.value.is_free)
        if( addeventForm.related_events && addeventForm.related_events.length > 0)
          {
            formData.append('related_events',
            JSON.stringify(addeventForm.related_events))
          }
        
          if(addeventForm.related_news && addeventForm.related_news.length > 0)
          {
            formData.append('related_news',
            JSON.stringify(addeventForm.value.related_news))
          }
        formData.append('orgnisers_array',
          JSON.stringify(addeventForm.value.orgnisers_array))
        formData.append('speakers_array',
          JSON.stringify(addeventForm.value.speakers_array))
        formData.append('date', addeventForm.value.date)
        formData.append('og_image', addeventForm.value.og_image)

        formData.append('mobile_header_image', addeventForm.value.mobile_header_image)
        formData.append('alt_mobile_header_image', addeventForm.value.alt_mobile_header_image)


        formData.append('agenda', JSON.stringify(daycontentArray.value))
        if (addeventForm.value.is_free != '1') {
          formData.append('end_early_bird_date', addeventForm.value.end_early_bird_date)
          formData.append('early_bird_price', addeventForm.value.early_bird_price)
          formData.append('regular_price', addeventForm.value.regular_price)
        }

        formData.append('_method', 'put')

        formData.append('venue', addeventForm.value.venue)

        addeventFormalidate.value.validate().then(success => {
          if (success) {
            store
              .dispatch('events/Updateevent', { id, formData })
              .then(response => {
                Vue.swal({
                  icon: 'success',
                  title: 'Updated!',

                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              })
          }
        })
      } else {
        Vue.swal({
          title: '',
          text: 'Please Enter Valid Link',
          icon: 'error',
          confirmButtonText: 'ok',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      }
    }
    function UploadImage(blobInfo, success, failure, progress) {
      let xhr; let
        formData
      const token = localStorage.getItem('token')

      xhr = new XMLHttpRequest()
      xhr.withCredentials = false
      xhr.open('POST', `${process.env.VUE_APP_ROOT_API}/upload_image_tiny_mce`)

      xhr.upload.onprogress = function (e) {
        progress(e.loaded / e.total * 100)
      }
      xhr.setRequestHeader('Authorization', `Bearer ${token}`)
      xhr.onload = function () {
        let json

        if (xhr.status === 403) {
          failure(`HTTP Error: ${xhr.status}`, { remove: true })
          return
        }

        if (xhr.status < 200 || xhr.status >= 300) {
          failure(`HTTP Error: ${xhr.status}`)
          return
        }

        json = JSON.parse(xhr.responseText)

        if (!json || typeof json.location !== 'string') {
          failure(`Invalid JSON: ${xhr.responseText}`)
          return
        }

        success(json.location)
      }

      xhr.onerror = function () {
        failure(`Image upload failed due to a XHR Transport error. Code: ${xhr.status}`)
      }

      formData = new FormData()
      formData.append('image', blobInfo.blob(), blobInfo.filename())

      xhr.send(formData)
    }
    const refInputE3 = ref(null)
    const refPreviewE3 = ref(null)
    const { inputImageRendererTab3 } = useInputImageRenderer(
      refInputE3,
      base64 => {
        refPreviewE3.value.src = base64
      },
    )
    const eventsList = ref([])
    const speakersList = ref([])
    const organisersList = ref([])
    
    const newsList = ref([])
    store.dispatch('events/Allevent').then(response => {
      eventsList.value = response.data
    })
    store.dispatch('speakers/Allspeakers').then(response => {
      speakersList.value = response.data
    })
    store.dispatch('events/AllNews').then(response => {
      newsList.value = response.data
    })

    store.dispatch('organisers/Allorganisers').then(response => {
      organisersList.value = response.data
    })

    const refPreviewEM = ref(null)
    const refInputEM= ref(null)
    const { inputImageRendererTab7 } = useInputImageRenderer(
      refInputEM,
      base64 => {
        refPreviewEM.value.src = base64
      },
    )
        const { proxy } = getCurrentInstance()
    const imageProperties = reactive({
      src: '',
      alt: '',
      width: '',
      height: '',
      alignment: '',
    })
    let editorSelection = null
    let currentEditor = null

    const imageHandler = editorRef => {
      currentEditor = proxy.$refs[editorRef].quill
      editorSelection = currentEditor.getSelection()
      const input = document.createElement('input')
      input.setAttribute('type', 'file')
      input.setAttribute('accept', 'image/*')
      input.click()

      input.onchange = async () => {
        const file = input.files[0]
        const formData = new FormData()
        formData.append('image', file)

        try {
          const token = localStorage.getItem('token')
          const xhr = new XMLHttpRequest()
          xhr.withCredentials = false
          xhr.open('POST', `${process.env.VUE_APP_ROOT_API}/upload_image_tiny_mce`)
          xhr.setRequestHeader('Authorization', `Bearer ${token}`)

          xhr.onload = function () {
            if (xhr.status === 403) {
              console.error(`HTTP Error: ${xhr.status}`)
              return
            }

            if (xhr.status < 200 || xhr.status >= 300) {
              console.error(`HTTP Error: ${xhr.status}`)
              return
            }

            const json = JSON.parse(xhr.responseText)

            if (!json || typeof json.location !== 'string') {
              console.error(`Invalid JSON: ${xhr.responseText}`)
              return
            }

            imageProperties.src = json.location
            proxy.$bvModal.show('image-properties-modal')
          }

          xhr.onerror = function () {
            console.error(`Image upload failed due to a XHR Transport error. Code: ${xhr.status}`)
          }

          xhr.send(formData)
        } catch (error) {
          console.error('Image upload failed:', error)
        }
      }
    }

    const insertImage = () => {
      const editor = currentEditor
    console.log(currentEditor.getSelection())
      const range = editorSelection
      if (!range) {
        console.error('No selection found')
        return
      }
      const { src, alt, width, height, alignment } = imageProperties
  
      editor.insertEmbed(range.index, 'image', { src, alt, width, height, class: alignment })
      proxy.$bvModal.hide('image-modal-post')
         imageProperties.src = '';
      imageProperties.alt = '';
      imageProperties.width = '';
      imageProperties.height = '';
      imageProperties.alignment = '';
    }


  const linkHandler = editorRef => {
  const range = proxy.$refs[editorRef].quill.getSelection()
  if (range) {
    const url = prompt('Enter the URL')
    if (url) {
      const openInNewTab = confirm('Open in new tab?')
      const selectedText = proxy.$refs[editorRef].quill.getText(range)
      const targetAttribute = openInNewTab ? ' target="oooooooooo" rel="noopener noreferrer"' : ''
      const linkValue = `<a href="${url}"${targetAttribute}>${selectedText}</a>`

      // Remove the selected text
      proxy.$refs[editorRef].quill.deleteText(range.index, range.length)

      // Insert the link at the original selection index
      proxy.$refs[editorRef].quill.clipboard.dangerouslyPasteHTML(range.index, linkValue)
    }
  }
}

    const getEditorOptions = editorRef => ({
      theme: 'snow',
      modules: {
        toolbar: {
          container: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: [] }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ direction: 'rtl' }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ['link', 'image', 'video'],
            [{ size: SizeStyle.whitelist }],
    ['bold', 'italic', 'underline', 'strike'],
  
    ['clean'],
    ['undo', 'redo'],
    ['paragraph']
          ],
          handlers: {
            image: () => imageHandler(editorRef),
            link: () => linkHandler(editorRef)
          },
        },
      },
    })

    return {
      daycontentArray,
      dayContent,
      durationList,
      dayContentForm,
      getDayContent,
      refInputEl,
      UploadImage,
      refPreviewEl,
      refInputE2,
      refPreviewE2,
      eventsList,
      newsList,
      inputImageRendererTab,
      refInputE3,
      refPreviewE3,
      refInputEN,
      refPreviewEN,
      Tabimage,
      addeventForm,
      speakersList,
      organisersList,
      refPreviewEM,
      refInputEM,
      inputImageRendererTab7,
      inputImageRendererTab2,
      refInputEP,
      refPreviewEP,
 linkHandler,
       imageHandler,
      insertImage,
      imageProperties,
      getEditorOptions,
      inputImageRendererTab5,
      refInputES,
      refPreviewES,
      getDurationList,
      inputImageRendererTab4,
      refInputEC,
      refPreviewEC,
      inputImageRendererTab3,
      inputImageRendererTab6,
      inputImageRenderer,
      addeventFormalidate,
      id,
      eventForm,
      removeSpace,
      validLink,
      checkLink,
      save,
      required,
      email,
    }
  },

  components: {
    BCard,
    BMedia,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BFormRadio,
    BFormCheckbox,
    Editor,
    flatPickr,
    BFormRadioGroup,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    quillEditor,
  },

  directives: {
    Ripple,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
<style>
  .mb-2.col-12 {
    margin: -1px !important;
  }
   .border.rounded.p-2 {
    margin: 8px 1px !important;
  }
  .col-12 {
    margin: 0px;
  }

  </style>
